<template>
  <div class="secondary--text text-center">
    <p class="ma-5 text-h2 font-weight-bold">
      {{ title }}
    </p>
    <v-container class="px-15 px-md-auto">
      <v-row class="py-9" justify="center">
        <v-col
          v-for="(type, i) in eventTypes"
          :key="i"
          cols="auto"
          class="mx-sm-5 d-flex flex-column align-center"
        >
          <v-img
            :src="require(`@/assets/icons/${type}.svg`)"
            alt
            contain
            class="icon"
          />
          <!-- // ! In case that custom icons are imported -->
          <!-- <v-icon size="75" color="secondary">{{ type.icon }}</v-icon> -->
          <p class="mt-3 mt-md-6 text-h4 font-weight-regular">
            {{ type }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider
      class="secondary mx-auto mb-10"
      :style="[
        $vuetify.breakpoint.xs ? { width: '306px' } : { width: '406px' },
      ]"
    ></v-divider>
    <div v-if="zaturnaInspiration">
      <p class="ma-5 mb-6 text-h3 font-weight-regular">Inspiración Zaturna</p>
      <v-container class="px-15 px-md-auto">
        <v-row justify="center" class="mb-3">
          <v-col
            v-for="(media, i) in socialMedia"
            :key="i"
            cols="auto"
            class="mx-md-2 d-flex flex-column align-center"
          >
            <a
              :href="media.externalUrl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-img
                :src="require(`@/assets/icons/${media.name}.svg`)"
                alt
                :class="media.imgStyle"
                contain
                class="icon"
                style="cursor: pointer"
              />
            </a>
            <!-- // ! In case that custom icons are imported -->
            <!-- <v-icon size="75" color="secondary">{{ media }}</v-icon> -->
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListCategories",
  props: {
    zaturnaInspiration: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    return {
      eventTypes: [
        "Cumpleaños",
        "Bodas",
        "Bautizos",
        "Showers",
        "Posadas",
        "Ejecutivos",
      ],
      socialMedia: [
        {
          name: "Facebook",
          externalUrl: "https://www.facebook.com/zaturna.eventos",
          imgStyle: "mt-3 mt-sm-0",
        },
        {
          name: "Instagram",
          externalUrl: "https://www.instagram.com/zaturna.eventos/",
          imgStyle: "mt-3 mt-sm-0",
        },
      ],
      // ! In case that custom icons are imported
      // socialMedia: ["far fa-star", "far fa-star", "far fa-star"],
    };
  },
};
</script>

<style></style>
