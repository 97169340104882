export const maxDigitsPhone = 10;
export const maxDigitsCode = 6;
export const maxDigitsPostalCode = 5;
export const minLengthPassword = 8;
export const maxDigitsProviderRFC = 13;
export const maxDigitsClientRFC = 13;
export const maxDigitsQuantity = 6;

export const passwordRules = [
  (v) => !!v || "Contraseña requerida",
  (v) =>
    (v && v.length >= minLengthPassword) || "Contraseña de mínimo 8 caracteres",
  (v) => /(?=.*\d)/.test(v) || "Al menos un número",
  (v) => /(?=.*[A-Z])/.test(v) || "Al menos una mayúscula",
  (v) => /(?=.*[a-z])/.test(v) || "Al menos una minúscula",
  (v) =>
    /(?=.*[@$!_.%*?&])/.test(v) ||
    "Al menos un carácter especial  @ . $ ! _ % * ? &",
];
export const confirmPasswordRules = [(v) => !!v || "Contraseña requerida"];
export const nameRules = [
  (v) => !!v || "Nombre requerido",
  (v) => (v && v.length >= 3) || "Al menos 3 caracteres",
  (v) =>
    /^[a-z 1234567890 áéíúóñ,.'-]+$/i.test(v) ||
    "Nombre contiene caracteres inválidos",
];
export const preferedNameRules = [
  (v) => !!v || "Nombre preferido requerido",
  (v) => (v && v.length >= 3) || "Al menos 3 caracteres",
  (v) =>
    /^[a-z áéíúóñ,.'-]+$/i.test(v) ||
    "Nombre preferido contiene caracteres inválidos",
];
export const legalNameRules = [
  (v) => !!v || "Nombre legal requerido",
  (v) => (v && v.length >= 3) || "Al menos 3 caracteres",
  (v) =>
    /^[a-z áéíúóñ,.'-]+$/i.test(v) ||
    "Nombre legal contiene caracteres inválidos",
];

export const stateRules = [
  (v) => !!v || "Estado requerido",
  (v) =>
    /^[a-z áéíúóñ,.'-]+$/i.test(v) || "Estado contiene caracteres no válidos",
];

export const stateRulesSelect = [(v) => !!v || "Elija un estado de la lista"];
export const cityRulesSelect = [(v) => !!v || "Elija una ciudad de la lista"];
export const cityRules = [
  (v) => !!v || "Ciudad requerida",
  (v) =>
    /^[a-z áéíúóñ,.'-]+$/i.test(v) || "Ciudad contiene caracteres no válidos",
];
export const streetRules = [(v) => !!v || "Calle requerida"];
export const externalAddressNumberRules = [
  (v) => !!v || "Número externo requerido",
  (v) => /^([0-9]?)+$/.test(v) || "Sólo números",
];
export const optionalInternalAddressNumberRules = [
  (v) =>
    !v ||
    /^[a-zA-Z0-9áéíúóñ&,.-\s]+$/.test(v) ||
    "Contiene caracteres no válidos",
];
export const optionalAddressNumberRules = [
  (v) => !v || /^([0-9]?)+$/.test(v) || "Sólo números",
];
export const emailRules = [
  (v) => !!v || "Correo requerido",
  (v) => /.+@.+\..+/.test(v) || "Correo no válido",
];
export const phoneRules = [
  (v) => !!v || "Teléfono requerido",
  (v) => /^\d+$/.test(v) || "Solo dígitos",
  (v) => (v && v.length == maxDigitsPhone) || "Debe contener 10 dígitos",
];
export const codeRules = [
  (v) => !!v || "Código requerido",
  (v) => /^\d+$/.test(v) || "Solo dígitos",
  (v) =>
    (v && v.length == maxDigitsCode) ||
    `Debe contener ${maxDigitsCode} dígitos`,
];

export const postalCodeRules = [
  (v) => !!v || "Código Postal requerido",
  (v) => /^\d+$/.test(v) || "Solo dígitos",
  (v) =>
    (v && v.length == maxDigitsPostalCode) ||
    `Debe contener ${maxDigitsPostalCode} dígitos`,
];

export const optionalPostalCodeRules = [
  (v) => !v || /^\d+$/.test(v) || "Solo dígitos",
  (v) =>
    !v ||
    (v || "").length == maxDigitsPostalCode ||
    `Debe contener ${maxDigitsPostalCode} dígitos`,
];

export const checkBoxRules = [
  (v) => !!v || "Tienes que aceptar antes de continuar",
];
export const numberInput = [(v) => /^\d+$/.test(v) || "Solo números"];
export const numberInputOptional = [
  (v) => /^([0-9]?)+$/.test(v) || "Solo números",
];
export const quantityInput = [
  (v) => /^.+$/.test(v) || "Llenar campo",
  (v) => /^\d+$/.test(v) || "Llenar campo correctamente",
  (v) => /^[0-9]+$/.test(v) || "Cantidad no válida",
  (v) =>
    (v && v.toString().length <= maxDigitsQuantity) ||
    "Debe contener máximo 6 cifras",
];
export const moneyRules = [
  (v) => !!v || "Cantidad requerida",
  (v) =>
    /^([1-9](\.[0-9]+)?)|(0\.[0-9]*[1-9])$/.test(v) || "Cantidad no válida",
  (v) =>
    /^[0-9]*(\.[0-9]{0,2})?$/.test(v) ||
    "Cantidad no válida. Hasta dos decimales permitidos",
];
export const fillInput = [(v) => !!v || "Llenar campo"];
export const twentyFourHourRules = [
  (v) => !!v || "Hora requerida",
  (v) =>
    /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) ||
    "Hora de 00 a 23. Minutos de 00 a 59",
];
export const timeRules = [
  (v) => !!v || "Hora requerida",
  (v) =>
    /^(0?[1-9]|1[0-2]):[0-5][0-9]$/.test(v) ||
    "Hora de 01 a 12. Minutos de 00 a 59",
];

export const rfcProviderRules = [
  (v) => !!v || "RFC requerido",
  (v) =>
    /^([A-ZÑ]){3,4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/.test(
      v
    ) || "Coloque un RFC valido",
];
export const rfcClientRules = [
  (v) => !!v || "RFC requerido",
  (v) =>
    /^([A-ZÑ]){3,4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/.test(
      v
    ) || "Coloque un RFC valido",
  (v) => (v && v.length == maxDigitsClientRFC) || "Debe contener 12 caracteres",
];

export const categoryRules = [(v) => !!v || "Elija una categoría"];

export const optionRules = [(v) => !!v || "Elija una opción"];

export const numberRules = [
  (v) => /(?=.*\d)/.test(v) || "Al menos un número",
  (v) => /^\d+$/.test(v) || "Solo números",
];

export const minRules = [
  (v) => /^.+$/.test(v) || "Llenar campo",
  (v) => /^\d+$/.test(v) || "Llenar campo correctamente",
  (v) => /^[0-9]+$/.test(v) || "Cantidad no válida",
];

export const maxRules = [
  (v) => /^.+$/.test(v) || "Llenar campo",
  (v) => /^\d+$/.test(v) || "Llenar campo correctamente",
  (v) => /^[0-9]+$/.test(v) || "Cantidad no válida",
];

export const descriptionRules = [
  (v) => !!v || "Descripción requerida",
  (v) =>
    (v && v.length <= 255) ||
    "Descripción no puede contener más de 255 caracteres",
];

export const optionalTextAreaRules = [
  (v) =>
    (v || "").length <= 255 ||
    "Campo de texto no puede contener más de 255 caracteres",
];

export const neighborRules = [(v) => !!v || "Colonia requerida"];
