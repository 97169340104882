var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple",value:({
      class: 'secondary--text text--lighten-1 card-shadow',
      center: true,
    }),expression:"{\n      class: 'secondary--text text--lighten-1 card-shadow',\n      center: true,\n    }"}],staticClass:"d-flex flex-column justify-space-between",attrs:{"tile":"","max-width":_vm.$vuetify.breakpoint.xs
        ? '238'
        : _vm.$vuetify.breakpoint.sm
        ? '250'
        : _vm.$vuetify.breakpoint.md
        ? '265'
        : '270',"to":_vm.getRoute}},[_c('div',[_c('v-img',{staticClass:"text-right pa-4 grey lighten-4",attrs:{"width":"270","height":"270","src":_vm.reservation.image_url}}),_c('v-container',{staticClass:"px-1 py-0"},[_c('v-card-title',{staticClass:"card-title break-word text-subtitle-1 text-left pt-6 mb-1"},[_vm._v(" "+_vm._s(_vm.reservation.name)+" ")]),_c('v-card-subtitle',{staticClass:"break-word text-body-2 font-weight-bold secondary--text text-left pb-1"},[_vm._v(" "+_vm._s(_vm.reservation.provider)+" ")]),_c('v-card-text')],1)],1),_c('div',[_c('v-container',{staticClass:"px-1 py-0"},[_c('v-card-text',[_c('div',{staticClass:"text-body-2 font-weight-regular black--text"},[_vm._v(" "+_vm._s(_vm.reservation.eventName)+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-progress-linear',{attrs:{"height":"6","color":"secondary","background-color":"#C4C4C499","rounded":""},model:{value:(_vm.percentageNumber),callback:function ($$v) {_vm.percentageNumber=$$v},expression:"percentageNumber"}}),_c('div',{staticClass:"pl-4 pr-2"},[_vm._v(" "+_vm._s(_vm.getPercentage(_vm.reservation.totalPaid, _vm.reservation.serviceCost))+"% ")])],1),_c('div',{staticClass:"text-body-2 pb-3"},[_vm._v(" Fecha: "+_vm._s(_vm.convertUnixToDate(_vm.reservation.eventDate))+" ")])])],1),_c('v-card-actions',{staticClass:"mx-1"},[_c('v-btn',{staticClass:"text-body-2 mb-2",class:hover && 'v-btn--active',attrs:{"plain":"","small":"","ripple":false,"to":_vm.getRoute}},[_vm._v(" Ver más › ")])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }