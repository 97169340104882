<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-ripple="{
        class: 'secondary--text text--lighten-1',
        center: true,
      }"
      tile
      :max-width="
        $vuetify.breakpoint.xs
          ? '238'
          : $vuetify.breakpoint.sm
          ? '250'
          : $vuetify.breakpoint.md
          ? '265'
          : '270'
      "
      class="d-flex flex-column justify-space-between"
      :to="getRoute"
    >
      <div>
        <v-img
          width="270"
          height="270"
          :src="request.image_url"
          class="text-right pa-4 grey lighten-4"
        >
        </v-img>
        <v-container class="px-1 py-0">
          <v-card-title
            class="card-title break-word text-subtitle-1 text-left pt-6 mb-1"
          >
            {{ request.name }}
          </v-card-title>
          <v-card-subtitle
            class="break-word text-body-2 font-weight-bold secondary--text text-left pb-1"
          >
            {{ request.provider }}
          </v-card-subtitle>
          <v-card-text>
            <v-row v-if="request.rating" class="mx-0">
              <v-rating
                readonly
                dense
                half-increments
                size="14"
                empty-icon=""
                full-icon="fa-star"
                half-icon="fa-star-half-alt"
                color="black"
                background-color="grey lighten-1"
                class="mb-1"
                :value="request.rating"
              />
              <span class="text-caption align-self-center black--text mx-2">
                {{ request.rating }}
              </span>
              <span class="text-caption align-self-center black--text">
                ({{ request.reviews }}
                {{ request.reviews == 1 ? "reseña" : "reseñas" }})
              </span>
            </v-row>
          </v-card-text>
        </v-container>
      </div>
      <v-card-actions class="d-flex flex-column align-start">
        <div class="mx-3 mb-4">
          <span class="text-body-2">
            Solicitud: {{ convertUnixToDate(request.date) }}
          </span>
          <div class="text-body-2">
            {{ request.eventName }}
          </div>
          <div class="mt-2 text-body-2">
            <div v-if="request.status === 'approved'">
              <v-icon size="medium" color="green" class="pr-1">
                fas fa-circle
              </v-icon>
              Aprobada
            </div>
            <div v-else-if="request.status === 'pending'">
              <v-icon size="medium" color="yellow" class="pr-1">
                fas fa-circle
              </v-icon>
              Pendiente
            </div>
            <div v-else-if="request.status === 'rejected'">
              <v-icon size="medium" color="red" class="pr-1">
                fas fa-circle
              </v-icon>
              Rechazada
            </div>
          </div>
        </div>
        <v-btn
          class="text-body-2 mx-1 my-2"
          :class="hover && 'v-btn--active'"
          plain
          small
          :ripple="false"
          :to="getRoute"
        >
          Ver más ›
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapState } from "vuex";
import { convertUnixToDate } from "@/Utils/dateConverter.js";

export default {
  props: {
    request: { type: Object, required: true },
  },
  data() {
    return {
      convertUnixToDate: convertUnixToDate,
    };
  },
  computed: {
    ...mapState({
      username(state) {
        return state.user.name;
      },
    }),
    getRoute() {
      return {
        name: "UserRequestsQuotation",
        params: {
          id: this.request.id,
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-btn.v-btn:not(.v-btn--round).v-size--default {
  padding: 9px !important;
  min-width: auto !important;
}

.card-title {
  line-height: 22px;
}
</style>
