<template>
  <div class="py-5">
    <v-card
      rounded="lg"
      max-width="1065"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { padding: '8px' }
          : { padding: '2px 2px 2px 15px' },
      ]"
      :class="isCentered && 'mx-auto'"
    >
      <v-container fluid class="pa-0">
        <v-row class="mx-0 my-auto" align="center" justify="space-between">
          <v-slide-x-transition hide-on-leave>
            <v-col
              v-if="
                (!$vuetify.breakpoint.xs && searchType !== 'service') ||
                ($vuetify.breakpoint.xs && !service)
              "
              cols="12"
              :md="
                searchType === 'provider' ? 3 : searchType === 'service' ? 0 : 5
              "
              :class="
                !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm ? 'pa-0' : ''
              "
            >
              <v-list-item class="pa-0 pl-md-1">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="font-weight-bold text-subtitle-2 mb-0 secondary--text no-select"
                  >
                    Proveedor
                  </v-list-item-title>
                  <v-hover v-slot="{ hover }">
                    <v-text-field
                      v-model="provider"
                      dense
                      flat
                      hide-details
                      color="secondary"
                      :solo="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                      :clearable="!$vuetify.breakpoint.xs"
                      :clear-icon="'mdi-close secondary--text'"
                      class="custom-text-field pa-0 ma-0"
                      :class="hover || provider ? 'v-input--is-focused' : ''"
                      placeholder="¿A quién buscas?"
                      maxlength="60"
                      @click:clear="
                        searchType = '';
                        provider = '';
                        state = '';
                        city = '';
                      "
                      @input="provider ? (searchType = 'provider') : ''"
                      @click="searchType = 'provider'"
                      @blur="
                        provider ? (searchType = 'provider') : (searchType = '')
                      "
                      @keyup.enter="provider && handleSearch(searchType)"
                    ></v-text-field>
                  </v-hover>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-slide-x-transition>
          <v-divider
            v-if="
              (!$vuetify.breakpoint.xs && !searchType) ||
              ($vuetify.breakpoint.xs && !provider && !service)
            "
            :vertical="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
            class="my-1 mx-0"
            :class="
              !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
                ? 'my-3'
                : 'my-0'
            "
            color="secondary"
          ></v-divider>
          <v-slide-x-reverse-transition hide-on-leave>
            <v-col
              v-if="
                (!$vuetify.breakpoint.xs && searchType !== 'provider') ||
                ($vuetify.breakpoint.xs && !provider)
              "
              cols="12"
              :md="
                searchType === 'service' ? 3 : searchType === 'provider' ? 0 : 4
              "
              :class="
                !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
                  ? 'pa-0 mx-0'
                  : ''
              "
            >
              <v-list-item class="pa-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="font-weight-bold text-subtitle-2 mb-0 secondary--text no-select"
                  >
                    Servicio
                  </v-list-item-title>
                  <v-hover v-slot="{ hover }">
                    <v-text-field
                      v-model="service"
                      dense
                      flat
                      hide-details
                      color="secondary"
                      :solo="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                      :clearable="!$vuetify.breakpoint.xs"
                      class="custom-text-field pa-0 ma-0"
                      :clear-icon="'mdi-close secondary--text'"
                      :class="hover || service ? 'v-input--is-focused' : ''"
                      placeholder="¿Qué buscas?"
                      maxlength="50"
                      @click:clear="
                        searchType = '';
                        service = '';
                        state = '';
                        city = '';
                      "
                      @input="service ? (searchType = 'service') : ''"
                      @click="searchType = 'service'"
                      @blur="
                        service ? (searchType = 'service') : (searchType = '')
                      "
                      @keyup.enter="service && handleSearch(searchType)"
                    ></v-text-field>
                  </v-hover>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-slide-x-reverse-transition>
          <v-divider
            v-if="searchType === 'service' || searchType === 'provider'"
            :vertical="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
            class="ma-1 my-2"
            :class="
              searchType && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
                ? 'my-0'
                : ''
            "
            color="secondary"
          />
          <v-col
            v-show="searchType === 'service' || searchType === 'provider'"
            cols="12"
            md="3"
            :class="
              !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm ? 'pa-0' : ''
            "
          >
            <v-list-item class="pa-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="font-weight-bold text-subtitle-2 mb-0 secondary--text no-select"
                >
                  Estado
                </v-list-item-title>

                <v-hover v-slot="{ hover }">
                  <v-autocomplete
                    v-model="state"
                    :items="
                      searchType === 'provider'
                        ? providersStates
                        : servicesStates
                    "
                    hide-details
                    single-line
                    dense
                    flat
                    color="secondary"
                    background-color="transparent"
                    item-color="secondary"
                    no-data-text="Estado no encontrado"
                    :menu-props="{
                      bottom: true,
                      closeOnContentClick: true,
                      allowOverflow: true,
                      offsetY: true,
                      rounded: 'md',
                      transition: 'slide-y-transition',
                    }"
                    :disabled="!service && !provider"
                    placeholder="¿En dónde es tu evento?"
                    class="py-0 my-0"
                    :class="hover || state ? 'v-input--is-focused' : ''"
                    @change="userStateUpdated"
                  >
                    <template v-slot:append>
                      <span class="v-input__icon--append">
                        <v-icon
                          size="30"
                          color="secondary"
                          class="v-input__icon--append"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-hover>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-divider
            v-if="searchType === 'service' || searchType === 'provider'"
            :vertical="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
            class="ma-1 my-2"
            :class="
              searchType && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
                ? 'my-0'
                : ''
            "
            color="secondary"
          />
          <v-col
            v-show="searchType === 'service' || searchType === 'provider'"
            cols="12"
            :md="searchType ? 2 : 2"
            :class="
              !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm ? 'pa-0' : ''
            "
          >
            <v-list-item class="pa-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="font-weight-bold text-subtitle-2 mb-0 secondary--text no-select"
                >
                  Ciudad
                </v-list-item-title>

                <v-hover v-slot="{ hover }">
                  <v-autocomplete
                    v-model="city"
                    :items="cities"
                    hide-details
                    single-line
                    dense
                    flat
                    color="secondary"
                    background-color="transparent"
                    item-color="secondary"
                    no-data-text="Seleccione un estado"
                    :menu-props="{
                      bottom: true,
                      closeOnContentClick: true,
                      allowOverflow: true,
                      offsetY: true,
                      rounded: 'md',
                      transition: 'slide-y-transition',
                    }"
                    :disabled="!service && !provider"
                    placeholder="¿En dónde es tu evento?"
                    class="py-0 my-0"
                    :class="hover || city ? 'v-input--is-focused' : ''"
                  >
                    <template v-slot:append>
                      <span class="v-input__icon--append">
                        <v-icon
                          size="30"
                          color="secondary"
                          class="v-input__icon--append"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-hover>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            cols="12"
            md="2"
            class="custom-btn pa-0 pt-3 pa-md-1 text-md-right"
          >
            <Button
              text="Buscar"
              block
              :horizontal-padding="8"
              :disabled="!provider && !service"
              class="mx-0 mt-3 ma-md-0"
              aria-label="Realizar busqueda"
              @event="handleSearch(searchType)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { stateRules } from "@/Utils/rules.js";
import {
  GET_PROVIDERS_DISTINCT_LOCATIONS,
  GET_SERVICES_DISTINCT_LOCATIONS,
} from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { getDistinctCitiesByState } from "@/Utils/locations.js";

export default {
  components: {
    Button,
  },
  props: {
    isCentered: { type: Boolean, default: true },
  },
  data() {
    return {
      searchType: "",
      provider: "",
      service: "",
      city: "",
      state: "",
      providersStates: [],
      servicesStates: [],
      stateRules: stateRules,
    };
  },
  computed: {
    cities() {
      const stateCities = getDistinctCitiesByState(
        this.state,
        this.searchType === "provider"
          ? this.providersCities
          : this.servicesCities
      );

      return stateCities;
    },
  },
  created() {
    this.fetchDistinctProvidersLocations();
    this.fetchDistinctServicesLocations();
  },
  methods: {
    async fetchDistinctProvidersLocations() {
      const { data, errors } = await useQuery(GET_PROVIDERS_DISTINCT_LOCATIONS);
      if (data) {
        this.providersStates = data.distinctProvidersLocations.states;
        this.providersCities = data.distinctProvidersLocations.cities;
      } else if (errors) {
        console.log(errors);
      }
    },
    async fetchDistinctServicesLocations() {
      const { data, errors } = await useQuery(GET_SERVICES_DISTINCT_LOCATIONS);
      if (data) {
        this.servicesStates = data.distinctServicesLocations.states;
        this.servicesCities = data.distinctServicesLocations.cities;
      } else if (errors) {
        console.log(errors);
      }
    },
    handleSearch(searchType) {
      this.provider = this.provider !== null && this.provider.trim();
      this.service = this.service !== null && this.service.trim();
      if (!this.provider && !this.service) {
        return;
      }
      const name =
        searchType === "provider"
          ? this.provider
          : searchType === "service"
          ? this.service
          : "";
      let routeName = this.$router.history.current.path.includes("usuario")
        ? "User"
        : "";
      routeName =
        searchType === "provider"
          ? routeName + "Providers"
          : searchType === "service"
          ? routeName + "Services"
          : "UserMainPage";
      let queryObject;
      if (this.state) {
        if (this.city) {
          queryObject = {
            nombre: name,
            estado: this.state,
            ciudad: this.city,
          };
        } else {
          queryObject = {
            nombre: name,
            estado: this.state,
          };
        }
      } else {
        queryObject = {
          nombre: name,
        };
      }
      this.provider = "";
      this.service = "";
      this.state = "";
      this.city = "";
      this.$router
        .push({
          name: routeName,
          query: queryObject,
        })
        .catch(() => {});
    },
    userStateUpdated() {
      this.city = "";
    },
  },
};
</script>

<style lang="scss">
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: black !important;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-input--is-focused .v-input__slot {
  color: var(--v-secondary-base);
}

.custom-btn .v-btn::before {
  background-color: transparent;
}

.custom-btn span {
  font-size: 1.1rem !important;
}

.custom-text-field.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding-left: 0;
}

.disable-events {
  pointer-events: none;
}
</style>
