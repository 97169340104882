<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-ripple="{
        class: 'secondary--text text--lighten-1 card-shadow',
        center: true,
      }"
      tile
      :max-width="
        $vuetify.breakpoint.xs
          ? '238'
          : $vuetify.breakpoint.sm
          ? '250'
          : $vuetify.breakpoint.md
          ? '265'
          : '270'
      "
      class="d-flex flex-column justify-space-between"
      :to="getRoute"
    >
      <div>
        <v-img
          width="270"
          height="270"
          :src="reservation.image_url"
          class="text-right pa-4 grey lighten-4"
        />

        <v-container class="px-1 py-0">
          <v-card-title
            class="card-title break-word text-subtitle-1 text-left pt-6 mb-1"
          >
            {{ reservation.name }}
          </v-card-title>
          <v-card-subtitle
            class="break-word text-body-2 font-weight-bold secondary--text text-left pb-1"
          >
            {{ reservation.provider }}
          </v-card-subtitle>
          <v-card-text>
            <!-- <v-rating
            readonly
            dense
            half-increments
            size="14"
            empty-icon=""
            full-icon="fa-star"
            half-icon="fa-star-half-alt"
            color="black"
            background-color="grey lighten-1"
            class="mb-1"
            :value="reservation.rating"
          ></v-rating>
          <span class="mx-2 black--text text-caption align-self-center">
            {{ reservation.rating }}
          </span>
          <span class="black--text text-caption align-self-center">
            ({{ reservation.reviews }} reseñas)
          </span> -->
          </v-card-text>
        </v-container>
      </div>
      <div>
        <v-container class="px-1 py-0">
          <v-card-text>
            <div class="text-body-2 font-weight-regular black--text">
              {{ reservation.eventName }}
            </div>
            <div class="d-flex align-center">
              <v-progress-linear
                v-model="percentageNumber"
                height="6"
                color="secondary"
                background-color="#C4C4C499"
                rounded
              />
              <div class="pl-4 pr-2">
                {{
                  getPercentage(reservation.totalPaid, reservation.serviceCost)
                }}%
              </div>
            </div>
            <div class="text-body-2 pb-3">
              Fecha:
              {{ convertUnixToDate(reservation.eventDate) }}
            </div>
            <!-- <v-row
              v-if="reservation.status === 'concluded'"
              class="mx-0 mt-5 ratings-grid"
              align="center"
              align-content="center"
            >
              <v-col cols="12" class="px-0" align="center">
                <Button 
                  v-if="reservation.isRated === false"
                  text="Calificar servicio"
                  block
                  class="mt-3"
                  aria-label="Calificar servicio"
                  @event="handleRatingModal()"
                />
                </v-btn>
                <span
                  v-else
                  class="d-flex text-center text-body-2 font-weight-medium"
                >
                  <v-icon size="14" color="secondary">fa-star</v-icon>
                  {{ reservation.myRating }} Calificación del evento
                </span>
              </v-col>
            </v-row> -->
          </v-card-text>
        </v-container>
        <v-card-actions class="mx-1">
          <v-btn
            class="text-body-2 mb-2"
            :class="hover && 'v-btn--active'"
            plain
            small
            :ripple="false"
            :to="getRoute"
          >
            Ver más ›
          </v-btn>
        </v-card-actions>
      </div>
      <!-- <ModalRating
      :dialog="ratingModal"
      :reservation="reservation"
      :modal-step="1"
      @closeModal="handleRatingModal"
    /> -->
    </v-card>
  </v-hover>
</template>

<script>
// import Button from "@/components/Shared/Button.vue";
// import ModalRating from "@/components/User/Shared/Modal/ModalRating.vue";
import { mapState } from "vuex";
import { convertUnixToDate } from "@/Utils/dateConverter";

export default {
  components: {
    // Button,
    // ModalRating,
  },
  props: {
    reservation: { type: Object, required: true },
  },
  data() {
    return {
      // ratingModal: false,
      percentageNumber: 0,
      convertUnixToDate: convertUnixToDate,
    };
  },
  computed: {
    ...mapState({
      username(state) {
        return state.user.name;
      },
    }),
    getRoute() {
      return {
        name:
          this.reservation.productType === "Servicio"
            ? "UserReceiptDetails"
            : "UserPackageReceiptDetails",
        params: {
          id: this.reservation.id,
        },
      };
    },
  },
  updated() {
    this.percentageNumber = this.getPercentage(
      this.reservation.totalPaid,
      this.reservation.serviceCost
    );
  },
  created() {
    this.percentageNumber = this.getPercentage(
      this.reservation.totalPaid,
      this.reservation.serviceCost
    );
  },
  methods: {
    // handleRatingModal() {
    //   this.ratingModal = !this.ratingModal;
    // },
    getPercentage(partialNumber, totalNumber) {
      let percentageDigit = partialNumber / totalNumber;
      let percentageNumber = percentageDigit * 100;
      return percentageNumber.toFixed(0);
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-btn.v-btn:not(.v-btn--round).v-size--default {
  padding: 9px !important;
  min-width: auto !important;
}

.card-title {
  line-height: 22px;
}
</style>
