<template>
  <v-footer
    :color="
      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'secondary' : 'primary'
    "
    class="white--text spacing-playground pa-6"
  >
    <v-row class="informationFooter" justify="center" no-gutters>
      <v-col cols="12" sm="3" align="center">
        <v-container>
          <img
            src="@/assets/icons/LogoZaturnaVertical.svg"
            alt="Logo Zaturna"
            :width="[
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '70%' : '40%',
            ]"
          />
        </v-container>
        <v-container>
          <p class="spacing-playground pt-3 text-body-2">
            ©{{ new Date().getFullYear() - 1 }} -
            {{ new Date().getFullYear() }}
          </p>
          <!-- <p class="spacing-playground text-body-3">
            <router-link :to="`/aviso_seguridad`" class="white--text"
              >Aviso de seguridad</router-link
            >
          </p> -->
        </v-container>
      </v-col>

      <!-- MOBILE -->
      <v-col cols="12" sm="12" align="center" class="hidden-sm-and-up">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header
              :color="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'secondary'
                  : 'primary'
              "
              class="white--text"
            >
              Información
              <template v-slot:actions>
                <v-icon color="white" class="text-body-1">
                  mdi-apple-keyboard-control
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              :color="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'secondary'
                  : 'primary'
              "
            >
              <v-list
                nav
                dense
                :color="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? 'secondary'
                    : 'primary'
                "
              >
                <v-list-item-group v-model="group">
                  <v-list-item
                    v-for="(itemInfo, idx) in itemsInformation"
                    :key="idx"
                    :to="getRoute(itemInfo.routeName)"
                  >
                    <v-list-item-title width="100%" class="white--text"
                      >{{ itemInfo.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col cols="12" sm="6" align="center" class="hidden-sm-and-up mb-5">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header
              :color="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'secondary'
                  : 'primary'
              "
              class="white--text"
            >
              Contacto
              <template v-slot:actions>
                <v-icon color="white" class="text-body-1">
                  mdi-apple-keyboard-control
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              :color="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'secondary'
                  : 'primary'
              "
              :style="{ 'max-width': '100vw' }"
            >
              <v-list
                nav
                dense
                :color="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? 'secondary'
                    : 'primary'
                "
              >
                <v-list-item-group v-model="group">
                  <v-list-item disabled>
                    <v-list-item-title class="white--text text-wrap"
                      >{{ itemsContact[0].title }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item disabled>
                    <v-list-item-title class="white--text text-wrap"
                      >{{ itemsContact[1].title }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item disabled>
                    <v-list-item-title class="white--text text-wrap"
                      >{{ itemsContact[2].title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- MOBILE -->

      <!-- TABLET & UP -->

      <v-col
        cols="12"
        sm="6"
        class="hidden-xs-only white--text"
        align-self="center"
      >
        <div class="d-flex justify-space-around">
          <div class="d-inline-flex flex-column">
            <h3 class="mb-2 text-h3 font-weight-bold">Información</h3>
            <div
              v-for="(itemInfoPc, idx) in itemsInformation"
              :key="idx"
              class="spacing-playground py-2 text-body-2 text-md-h4"
            >
              <router-link
                class="white--text text-decoration-none"
                :to="getRoute(itemInfoPc.routeName)"
              >
                {{ itemInfoPc.title }}
              </router-link>
            </div>
          </div>
          <div class="d-inline-flex flex-column">
            <h3 class="mb-2 text-h3 font-weight-bold">Contacto</h3>
            <div
              v-for="(itemContactPc, idx) in itemsContact"
              :key="idx"
              class="spacing-playground py-2 text-body-2 text-md-h4"
              :style="{ width: '160px' }"
            >
              {{ itemContactPc.title }}
            </div>
          </div>
        </div>
      </v-col>

      <!-- TABLET & UP -->

      <v-col cols="12" sm="3" align="center">
        <v-container>
          <h3 class="text-h3 font-weight-bold">Redes Sociales</h3>
          <v-card-text class="mt-3">
            <v-btn
              v-for="(icon, idx) in icons"
              :key="idx"
              icon
              router
              _blank
              :href="icon.path"
              target="_blank"
              rel="noopener noreferrer"
              class="ma-2"
            >
              <v-img
                :src="require(`@/assets/icons/${icon.title}Blanco.svg`)"
                alt
                contain
                class="icon"
              />
              <!-- <v-icon class="text-h2 white--text">
                {{ icon.title }}
              </v-icon> -->
            </v-btn>
          </v-card-text>
        </v-container>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    item: null,
    aviso_seguridad: null,
    itemsInformation: [
      {
        title: "¿Quiénes somos?",
        routeName: "WhoAreWeUser",
      },
      {
        title: "Política de privacidad",
        routeName: "TermsOfServiceUser",
      },
      // {
      //   title: "Alta de Proveedores",
      //   routeName: "SupplierRegistration",
      // },
      { title: "Ayuda", routeName: "UserHelp" },
    ],
    itemsContact: [
      {
        title:
          "Paseos Vista del Sol 6801, Vista del Sol, 31206 Chihuahua, Chih.",
      },
      { title: "614 278 2066" },
      { title: "contacto@zaturna.com" },
    ],
    icons: [
      {
        title: "Facebook",
        path: "https://www.facebook.com/zaturna.eventos",
      },
      {
        title: "Instagram",
        path: "https://www.instagram.com/zaturna.eventos/",
      },
      // {
      //   title: "Pinterest",
      //   path: "http://www.pinterest.com",
      // },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    getRoute(routeName) {
      if (
        this.$router.history.current.path.includes("usuario") &&
        routeName === "Help"
      ) {
        return { name: "User" + routeName };
      } else {
        return { name: routeName };
      }
    },
  },
};
</script>

<style scoped>
.v-expansion-panel {
  color: white;
}
</style>
