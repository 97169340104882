<template>
  <v-card class="fill-height hidden-sm-and-down" elevation="4" tile>
    <div class="d-flex align-center px-5 py-4">
      <span v-for="(section, i) in userNav" :key="i">
        <v-btn
          depressed
          text
          :ripple="false"
          class="text-h4 btn-background-transparent v-btn--active px-3"
          active-class="navbar-active font-weight-bold"
          router
          :to="{ name: section.routerName }"
        >
          <v-img class="mr-4" :src="section.icon" />
          <span class="navbar-title">{{ section.title }}</span>
        </v-btn>
      </span>
    </div>
  </v-card>
</template>

<script>
import CalendarIcon from "@/assets/icons/Calendar.svg";
import RequestsIcon from "@/assets/icons/Requests.svg";
import PaymentsIcon from "@/assets/icons/Payments.svg";

export default {
  data: () => ({
    drawer: false,
    group: null,
    userNav: [
      {
        title: "Mis reservaciones",
        routerName: "UserReservations",
        icon: CalendarIcon,
      },
      {
        title: "Solicitudes de proveedores",
        routerName: "UserRequests",
        icon: RequestsIcon,
      },
      {
        title: "Pagos",
        routerName: "UserPayments",
        icon: PaymentsIcon,
      },
      // {
      //   title: "Mis favoritos",
      //   routerName: "UserFavorites",
      //   icon: "far fa-heart",
      // },
    ],
  }),
};
</script>

<style scoped lang="scss">
.v-btn {
  span {
    font-size: 18px !important;
  }
  &:hover {
    .navbar-title {
      transform: scale(1.006);
    }
  }
  &.navbar-active .navbar-title {
    transform: scale(1.01);
  }
}

.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
