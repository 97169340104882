<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>

    <div
      class="body-container-60"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div class="text-h1 font-weight-black mb-5">Mis reservaciones</div>

        <v-tabs
          v-model="tab"
          color="secondary"
          background-color="transparent"
          show-arrows
          slider-size="5"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(item, i) in tabs"
            :key="i"
            class="btn-background-transparent text-capitalize black--text text-h3 px-3 px-sm-6"
            :ripple="false"
            :active-class="'font-weight-bold'"
          >
            <div @click="changeTab(item.id)">
              {{ item.title }}
            </div>
          </v-tab>
        </v-tabs>
        <v-divider color="secondary"></v-divider>
        <!-- <div class="d-flex justify-start justify-md-end mt-8">
          <v-sheet
            height="45"
            width="45"
            rounded
            class="tab-box d-flex align-center pa-2 mr-3"
            @click="setActiveTab('grid')"
          >
            <v-img :src="activeTab === 'grid' ? GridIcon : GridGrayIcon" />
          </v-sheet>
          <v-sheet
            height="45"
            width="45"
            rounded
            class="tab-box d-flex align-center pa-2"
            @click="setActiveTab('calendar')"
          >
            <v-img
              :src="activeTab === 'calendar' ? CalendarIcon : CalendarGrayIcon"
            />
          </v-sheet>
        </div> -->
        <div
          class="my-12 my-md-0"
          :class="!loading && filterRequests.length != 0"
        >
          <div v-if="!loading">
            <ServiceCardList
              v-if="activeTab === 'grid' && filterRequests.length > 0"
              :services="filterRequests"
              type="reservation"
            />
            <!-- <ServiceCalendar
              v-else-if="activeTab === 'calendar' && filterRequests.length > 0"
              :event-data="filterRequests"
            /> -->
            <div
              v-else
              class="text-center text-body-1 text-sm-h6 font-weight-regular mt-7"
            >
              No se han encontrado reservaciones
            </div>
            <v-lazy
              :options="{ threshold: 1 }"
              transition="scroll-y-reverse-transition"
            >
              <v-divider
                v-if="filterRequests.length != 0"
                class="mx-6 mt-16 secondary px-16"
              ></v-divider>
            </v-lazy>
          </div>

          <v-container v-else class="d-flex flex-column align-center my-16">
            <v-progress-circular
              indeterminate
              color="secondary"
              :size="100"
              class="my-16"
            ></v-progress-circular>
          </v-container>
        </div>

        <div
          :style="[
            filterRequests.length != 0
              ? { 'padding-top': '70px' }
              : { 'padding-top': '216px' },
          ]"
        >
          <ListCategories
            :zaturna-inspiration="true"
            title="Servicios para todo tipo de eventos"
          />
        </div>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
// import GridIcon from "@/assets/icons/shared/Grid.svg";
// import GridGrayIcon from "@/assets/icons/shared/GridGray.svg";
// import CalendarIcon from "@/assets/icons/shared/Calendar.svg";
// import CalendarGrayIcon from "@/assets/icons/shared/CalendarGray.svg";
import ServiceCardList from "@/components/User/Shared/ServiceCardList.vue";
// import ServiceSearchBar from "@/components/User/MyReservations/ServiceSearchBar.vue";
// import ServiceCalendar from "@/components/User/MyReservations/ServiceCalendar.vue";
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import { mapState } from "vuex";
import { useQuery } from "@/graphql/index";
import { GET_USER_RESERVATIONS } from "@/graphql/queries";
import Footer from "@/components/Shared/Footer.vue";
import ListCategories from "../../components/User/MainPage/ListCategories.vue";
import { mergeProductsLists } from "@/Utils/Utils.js";
import { convertDateToCstTimeZoneUnix } from "@/Utils/dateConverter.js";

export default {
  name: "MyReservations",
  components: {
    Navbar,
    UserNavbar,
    ServiceCardList,
    SearchBar,
    // ServiceSearchBar,
    // ServiceCalendar,
    Footer,
    ListCategories,
  },
  data() {
    return {
      // GridIcon: GridIcon,
      // GridGrayIcon: GridGrayIcon,
      // CalendarIcon: CalendarIcon,
      // CalendarGrayIcon: CalendarGrayIcon,
      reservations: [],
      tabs: [
        { id: "confirmed", title: "Confirmadas" },
        { id: "concluded", title: "Concluidas" },
      ],
      tab: 0,
      activeTab: "grid",
      loading: true,
    };
  },
  computed: mapState({
    user(state) {
      if (state.user) {
        return state.user;
      }
    },
    filterRequests() {
      return this.reservations.filter((req) => {
        switch (this.tab) {
          case 0:
            return req.status === "reservation";
          case 1:
            return req.status === "completed";
          default:
            return false;
        }
      });
    },
  }),
  watch: {
    user: {
      async handler(user) {
        if (!user) return;
        this.fetchUserReservations(user.id);
      },
      immediate: true,
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async fetchUserReservations(userId) {
      const { data, errors } = await useQuery(GET_USER_RESERVATIONS, {
        userId: parseInt(userId),
      });
      if (data) {
        this.serviceReservations = this.formatServiceReservations(
          data.userReservations
        );
        this.packageReservations = this.formatPackageReservations(
          data.userPackageReservations
        );
        this.reservations = mergeProductsLists(
          this.packageReservations,
          this.serviceReservations
        );
      } else {
        console.log(errors);
      }
      this.loading = false;
    },
    formatServiceReservations(reservations) {
      return reservations.map((reservation) => {
        return {
          id: reservation.reservation.id,
          name: reservation.service.name,
          provider: reservation.provider.name,
          eventDate: reservation.event.date,
          eventName: reservation.quotationRequest.eventType,
          reservationStatus: reservation.reservation.status,
          totalPaid: reservation.reservation.totalPaid,
          status: reservation.quotationRequest.status,
          serviceCost: reservation.quotationRequest.totalPayment,
          image_url: reservation.service.serviceImage[0].url,
          productType: "Servicio",
          date: convertDateToCstTimeZoneUnix(
            reservation.reservation.insertedAt
          ),
        };
      });
    },
    formatPackageReservations(reservations) {
      return reservations.map((reservation) => {
        return {
          id: reservation.id,
          name: reservation.packageName,
          provider: reservation.providerName,
          eventDate: reservation.eventDate,
          eventName: reservation.eventName,
          reservationStatus: reservation.status,
          totalPaid: reservation.totalPaid,
          status:
            reservation.status == "confirmed" || reservation.status == "paid"
              ? "reservation"
              : reservation.status,
          serviceCost: reservation.totalPayment,
          image_url: reservation.package.packageImages[0].url,
          productType: "Paquete",
          date: convertDateToCstTimeZoneUnix(reservation.insertedAt),
        };
      });
    },
    changeTab(id) {
      if (id === "confirmed") {
        this.viewOption = "grid";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.tab-box {
  cursor: pointer;
  border: 1px solid var(--v-secondary-base);
  &:hover {
    background-color: rgba(98, 37, 130, 0.1);
  }
}
</style>
