const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

/**
 * Convert yyyy-mm-dd to dd/mm/yyyy
 * @param {*} unixDate Date in yyyy-mm-dd
 * @returns {string} Date in dd/mm/yyyy format
 */
export const convertYYYYMMDDToDDMMYYYY = (date) => {
  if (!date) return null;
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

/**
 * Convert date to unix timestamp date at midnight
 * @param {*} date Date in yyyy-mm-dd format
 * @returns {Number} Unix timestamp date at midnight (23:59 p.m.)
 */
export const convertYYYYMMDDToUnixAtMidnight = (date) => {
  if (!date) return null;
  //Unix time in GMT+0000 (UTC+0)
  const time = new Date(date).getTime() / 1000;
  //Unix time in GMT-0600 (System time zone)
  const systemTime = time - 21600;
  //Sum one day (60 * 60 * 24)
  const timePlusOneDay = systemTime + 86400;
  //Substract one minute (60)
  const timeMinusOneMinute = timePlusOneDay - 60;
  return timeMinusOneMinute;
};

/**
 * Convert date, hour and daytime to unix timestamp date
 * @param {*} date Date in yyyy-mm-dd format
 * @param {*} hour Hour and minute in hh:mm format
 * @param {*} daytime AM or PM (12-hour clock time convention)
 * @returns {string} Unix timestamp date
 */
export const convertDateToUnix = (date, hour, daytime) => {
  const hours = parseInt(hour.slice(0, 2));
  const minutes = parseInt(hour.slice(3, 5));

  const daytimedHours =
    daytime === "AM"
      ? hours === 12
        ? hours - 12
        : hours
      : hours === 12
      ? hours
      : hours + 12;

  const newDate = new Date(
    date.slice(0, 4),
    date.slice(5, 7) - 1,
    date.slice(8, 10),
    daytimedHours.toString(),
    minutes.toString()
  );

  return Math.floor(newDate.getTime() / 1000).toString();
};

/**
 * Convert date with hour in twenty four hour format to unix timestamp date
 * @param {*} date Date in yyyy-mm-dd format
 * @param {*} hour Hour and minute in hh:mm format
 * @returns {string} Unix timestamp date
 */
export const convertDateWithTwentyFourHourToUnix = (date, hour) => {
  const newDate = new Date(
    date.slice(0, 4),
    date.slice(5, 7) - 1,
    date.slice(8, 10),
    hour.slice(0, 2),
    hour.slice(3, 5)
  );

  return Math.floor(newDate.getTime() / 1000).toString();
};

/**
 * Convert date to CST time zone unix timestamp date
 * @param {*} date Date in yyyy-mm-dd hh:mm:ss format
 * @returns {Number} CST time zone unix timestamp date
 */
export const convertDateToCstTimeZoneUnix = (date) => {
  //Unix time in GMT+0000 (UTC+0)
  const unixDate = new Date(date).getTime() / 1000;
  //Unix time in GMT-0600 (System time zone)
  const cstTimeZoneUnixDate = unixDate - 21600;
  return cstTimeZoneUnixDate;
};

/**
 * Convert unix timestamp date to date in Day Month Year format
 * @param {*} unixDate Unix timestamp date
 * @returns {string} Date in Day Month Year format
 */
export const convertUnixToDate = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const day = date.getDate();
  let month = date.getMonth();
  month = months[month];
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

/**
 * Convert unix timestamp date to Month Year
 * @param {*} date Date in unix timestamp date format
 * @returns {string} Date in Month Year format
 */
export const convertUnixToSemiCompleteDate = (unixDate) => {
  if (!unixDate) return null;
  const date = new Date(unixDate * 1000);
  let month = date.getMonth();
  month = months[month];
  const year = date.getFullYear();
  return `${month} ${year}`;
};

/**
 * Convert unix timestamp date to dd/mm/yyyy
 * @param {*} unixDate Unix timestamp date
 * @returns {string} Date in dd/mm/yyyy format
 */
export const convertUnixToDDMMYYYY = (unixDate) => {
  const date = new Date(unixDate * 1000);
  let day = date.getDate().toString();
  if (day.length < 2) day = "0" + day;
  let month = (date.getMonth() + 1).toString();
  if (month.length < 2) month = "0" + month;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

/**
 * Convert unix timestamp date to yyyy-mm-dd
 * @param {*} unixDate Unix timestamp Date
 * @returns {string} Date in yyyy-mm-dd format
 */
export const convertUnixToYYYYMMDD = (unixDate) => {
  const date = new Date(unixDate * 1000);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

/**
 * Convert unix timetamp date to hour and minute
 * @param {*} unixDate Unix timestamp date
 * @returns {string} Hour and minute in hh:mm am/pm (12-hour clock) format
 */
export const convertUnixToHourMinute = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const hour = date.getHours();
  const minute = date.getMinutes();
  let newHour = 0;
  let newMinute = 0;
  let timeCheck = "";
  hour == 0
    ? ((newHour = 12), (timeCheck = "a.m."))
    : hour == 12
    ? ((newHour = 12), (timeCheck = "p.m."))
    : hour > 12
    ? ((newHour = hour - 12), (timeCheck = "p.m."))
    : ((newHour = hour), (timeCheck = "a.m."));
  minute < 10 ? (newMinute = `0${minute}`) : (newMinute = minute);
  return `${newHour}:${newMinute} ${timeCheck}`;
};

/**
 * Convert unix timestamp date to AM or PM
 * @param {*} unixDate Unix timestamp date
 * @returns {string} AM or PM (12-hour clock time convention)
 */
export const convertUnixToAmPm = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const hour = date.getHours();
  let timeCheck = "";
  hour < 12 ? (timeCheck = "AM") : (timeCheck = "PM");
  return `${timeCheck}`;
};

/**
 * Convert unix timestamp date to hour and minute format for edit mode
 * @param {*} unixDate Unix timestamp date
 * @returns {string} Hour and minute in hh:mm am/pm (12-hour clock) format
 */
export const unixToHourMinuteEdit = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const hour = date.getHours();
  const minute = date.getMinutes();
  let newHour = 0;
  let newMinute = 0;
  let correctHour = 0;
  hour == 0 || hour == 12
    ? (newHour = 12)
    : hour > 12
    ? (newHour = hour - 12)
    : (newHour = hour);
  minute < 10 ? (newMinute = `0${minute}`) : (newMinute = minute);
  newHour < 10 ? (correctHour = `0${newHour}`) : (correctHour = newHour);
  return `${correctHour}:${newMinute}`;
};

/**
 * Input 2 unix dates in any order to get the difference in days
 * @param {*} firstDate A 10 digit unix timestamp date
 * @param {*} secondDate A 10 digit unix timestamp date
 * @returns {Number} The difference of days
 */
export const dateDifference = (firstDate, secondDate) => {
  const diffInSec = Math.abs(secondDate - firstDate);
  const secondsInDay = 60 * 60 * 24;
  const dayDifference = Math.floor(diffInSec / secondsInDay);
  return dayDifference;
};
