<template>
  <v-btn
    :aria-label="ariaLabel"
    :color="color"
    depressed
    :elevation="elevation"
    dark
    :download="download"
    exact
    :block="block"
    :loading="loading"
    :height="height"
    :outlined="outlined"
    :ripple="false"
    :class="buttonClass"
    :target="isExternal ? '_blank' : ''"
    :rel="isExternal ? 'noopener noreferrer' : ''"
    :href="isExternal ? url : ''"
    :to="internalRedirect"
    @click="handleClick"
  >
    <v-icon v-if="icon" size="20">{{ icon }}</v-icon>
    <v-icon v-if="prependIcon" class="mr-3" size="20">{{ prependIcon }}</v-icon>
    <span
      :class="outlined && (color === 'white' ? 'white--text' : 'black--text')"
    >
      {{ text }}
    </span>
    <v-icon v-if="appendIcon" class="ml-3" :size="pill ? 16 : 20">
      {{ appendIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "" },
    url: { type: String, default: "" },
    urlParams: { type: Object, default: () => ({}) },
    isExternal: { type: Boolean, default: false },
    ariaLabel: { type: String, required: true },
    color: { type: String, default: "secondary" },
    active: { type: Boolean, default: false },
    glass: { type: Boolean, default: false },
    elevation: { type: Number, default: 0 },
    rounded: { type: String, default: "lg" },
    outlined: { type: Boolean, default: false },
    height: { type: Number, default: 50 },
    horizontalPadding: { type: Number, default: 12 },
    pill: { type: Boolean, default: false },
    icon: { type: String, default: null },
    prependIcon: { type: String, default: null },
    appendIcon: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    download: { type: String, default: "" },
  },
  data: () => ({}),
  computed: {
    buttonClass() {
      const rounded = this.pill ? "rounded-pill" : `rounded-${this.rounded}`;
      const paddingX = this.icon
        ? "icon-btn"
        : this.pill
        ? "px-4"
        : `px-6 px-sm-${this.horizontalPadding}`;
      const disabled = this.disabled ? "disable-events" : "";
      const glass = this.glass ? "glass-btn" : "";
      const active = this.active ? "primary-active-btn" : "";
      return `${rounded} text-h4 py-3 ${paddingX} ${disabled} ${glass} ${active}`;
    },
    internalRedirect() {
      return this.url && !this.isExternal
        ? {
            name: this.url,
            params: this.urlParams,
          }
        : "";
    },
  },
  methods: {
    handleClick() {
      this.$emit("event");
    },
  },
};
</script>

<style scoped lang="scss">
.disable-events {
  pointer-events: none;
}

.icon-btn.v-btn:not(.v-btn--round).v-size--default {
  padding: 10px !important;
  min-width: auto !important;
}

.glass-btn {
  background: rgba(255, 255, 255, 0.3) !important;
}

.primary-active-btn {
  background-color: rgba(98, 37, 130, 0.1) !important;
}
</style>
