<template>
  <div>
    <!-- This ifs are temporary until we add back end implementation -->
    <v-lazy
      :options="{ threshold: 1 }"
      transition="scroll-y-reverse-transition"
    >
      <v-row
        v-if="type == 'reservation'"
        class="my-md-8 justify-center justify-sm-start"
      >
        <v-col
          v-for="(service, i) in services"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="d-flex justify-center py-4 py-md-5"
        >
          <div class="d-flex justify-center">
            <ReservationCard :reservation="service" />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-else-if="type == 'request'"
        class="my-md-8 justify-center justify-sm-start"
      >
        <v-col
          v-for="(service, i) in services"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="d-flex justify-center py-4 py-md-5"
        >
          <div class="d-flex justify-center">
            <RequestServiceCard :request="service" />
          </div>
        </v-col>
      </v-row>
    </v-lazy>
  </div>
</template>

<script>
import ReservationCard from "@/components/User/Shared/ReservationCard";
import RequestServiceCard from "@/components/User/Shared/RequestServiceCard";
export default {
  name: "ServiceCardList",
  components: { ReservationCard, RequestServiceCard },
  props: {
    services: { type: Array, required: true },
    type: { type: String, required: true },
  },
};
</script>
