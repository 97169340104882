<template>
  <div
    :class="
      page === 'main-page' &&
      !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) &&
      'purple-gradient'
    "
  >
    <v-app-bar
      :height="$vuetify.breakpoint.xs ? 70 : 88"
      :flat="
        !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) || page === 'user'
      "
      :color="
        ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) &&
        page === 'main-page'
          ? 'primary'
          : 'transparent'
      "
      class="px-2 px-sm-5"
    >
      <v-app-bar-nav-icon
        dark
        :ripple="false"
        class="hidden-md-and-up"
        @click="drawer = true"
      />
      <v-btn
        depressed
        plain
        dark
        :ripple="false"
        :to="{ name: 'UserMainPage' }"
        class="hidden-sm-and-down pl-0"
      >
        <v-img src="@/assets/icons/LogoZaturnaHorizontal.svg" />
      </v-btn>
      <v-toolbar-items class="hidden-sm-and-down align-center">
        <span v-for="(section, i) in desktopNavbar" :key="i">
          <v-btn
            v-if="!section.menu"
            depressed
            text
            dark
            :ripple="false"
            class="text-h4 btn-background-transparent v-btn--active"
            active-class="navbar-active font-weight-bold"
            router
            :exact="section.title === 'Inicio'"
            :to="{ name: section.routerName }"
          >
            <span class="navbar-title">{{ section.title }}</span>
          </v-btn>
          <v-menu
            v-else
            v-model="section.menuHandler"
            offset-y
            rounded="md"
            transition="slide-y-transition"
            open-on-hover
            :close-on-content-click="false"
            :open-delay="350"
            :close-delay="100"
            min-width="658"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                text
                dark
                :ripple="false"
                class="text-caption btn-background-transparent v-btn--active"
                :class="section.menuHandler && 'navbar-active font-weight-bold'"
                active-class="navbar-active font-weight-bold"
                router
                :to="{ name: section.routerName }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="navbar-title">{{ section.title }}</span>
              </v-btn>
            </template>
            <v-card class="py-6 px-9">
              <v-card-title class="pa-0 font-weight-bold text-h5">
                Servicios Zaturnas
              </v-card-title>
              <div class="my-2 ml-1 font-weight-bold text-subtitle-1">
                Los más populares
              </div>
              <v-card-text class="pa-0 text-subtitle-1">
                <v-row class="ma-0">
                  <v-col
                    cols="5"
                    class="px-4 py-2 purple-gradient-background rounded-md"
                    style="width: 234px"
                  >
                    <!-- //! Change way of determining the most popular services array -->
                    <div
                      v-for="(item, idx) in section.menu.slice(0, 6)"
                      :key="idx"
                      :class="idx == 0 ? 'mt-0' : 'mt-2'"
                    >
                      <v-hover v-slot="{ hover }">
                        <router-link
                          :to="{
                            name: section.routerName,
                            query: {
                              tipo: item,
                            },
                          }"
                          class="text-decoration-none black--text"
                          :class="
                            hover
                              ? 'text-decoration-underline font-weight-medium'
                              : ''
                          "
                        >
                          <span @click="section.menuHandler = false">
                            {{ item }}
                          </span>
                        </router-link>
                      </v-hover>
                    </div>
                  </v-col>
                  <v-col cols="7" class="px-5 py-2 two-column-list">
                    <div
                      v-for="(item, idx) in section.menu.slice(6, 17)"
                      :key="idx"
                      class="ml-5"
                      :class="idx == 0 ? 'mt-0' : 'mt-2'"
                    >
                      <v-hover v-slot="{ hover }">
                        <router-link
                          :to="{
                            name: section.routerName,
                            query: {
                              tipo: item,
                            },
                          }"
                          class="text-decoration-none black--text"
                          :class="
                            hover &&
                            'text-decoration-underline font-weight-medium'
                          "
                        >
                          <span @click="section.menuHandler = false">
                            {{ item }}
                          </span>
                        </router-link>
                      </v-hover>
                    </div>

                    <div class="ml-5 mt-2">
                      <v-hover v-slot="{ hover }">
                        <router-link
                          :to="{
                            name: section.routerName,
                            params: { autofocusServices: true },
                          }"
                          replace
                          class="text-decoration-none font-weight-bold black--text"
                          :class="hover && 'text-decoration-underline'"
                        >
                          <span @click="section.menuHandler = false">
                            Ver todos
                          </span>
                        </router-link>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </span>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items style="overflow-x: hidden">
        <v-btn
          depressed
          text
          dark
          :ripple="false"
          class="text-caption btn-background-transparent v-btn--active"
          :to="{ name: provider ? 'ProviderHomepage' : 'RegisterProvider' }"
        >
          <span class="navbar-title font-weight-bold">
            {{ provider ? "Modo proveedor" : "Registro proveedor" }}
          </span>
        </v-btn>
        <v-menu
          offset-y
          offset-x
          :open-on-hover="!$vuetify.breakpoint.xs"
          rounded="md"
          transition="slide-y-transition"
          max-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <a
              class="d-flex align-center pl-1 my-2 my-sm-4"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                v-if="user['photo']"
                :aspect-ratio="1"
                :src="user['photo']"
                width="34"
                class="rounded-circle"
              />
              <v-icon v-else size="30" color="white">fa-user-circle</v-icon>
              <span
                v-if="user['preferedName']"
                class="hidden-md-and-down white--text ml-3"
              >
                {{ user["preferedName"] }}
              </span>
            </a>
          </template>
          <v-card tile min-width="180px">
            <v-list dense class="py-0">
              <v-list-item
                v-for="(item, i) in menu"
                :key="i"
                :class="i == 0 ? 'pt-1' : i == menu.length - 1 && 'pb-1'"
                @click="handleMenuRedirect(item.routeName)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-subtitle-1"
                    :class="{
                      'font-weight-black': item.bold,
                    }"
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary :ripple="false">
      <v-btn
        :height="$vuetify.breakpoint.xs ? 70 : 88"
        depressed
        plain
        :ripple="false"
        class="d-flex justify-center primary rounded-0 v-btn--active"
        :to="{ name: 'UserMainPage' }"
        @click="drawer = false"
      >
        <v-img
          :height="$vuetify.breakpoint.xs ? 45 : 53"
          contain
          src="@/assets/icons/LogoZaturnaHorizontal.svg"
        />
      </v-btn>
      <v-list class="py-0">
        <v-list-item-group v-model="group" active-class="secondary--text">
          <v-list-item
            v-for="(item, i) in mobileNavbar"
            :key="i"
            router
            :to="{ name: item.routerName }"
            :exact="item.title === 'Inicio'"
          >
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Auth } from "aws-amplify";
export default {
  name: "Navbar",
  props: {
    purpleBkg: {
      type: Boolean,
      default: false,
    },
    page: { type: String, default: "user" },
  },
  data: () => ({
    drawer: false,
    group: null,
    mobileNavbar: [
      {
        title: "Inicio",
        routerName: "UserMainPage",
      },
      {
        title: "Proveedores",
        routerName: "UserProviders",
      },
      {
        title: "Servicios",
        routerName: "UserServices",
      },
      {
        title: "Paquetes",
        routerName: "UserPackages",
      },
      {
        title: "Mis reservaciones",
        routerName: "UserReservations",
      },
      {
        title: "Solicitudes de proveedores",
        routerName: "UserRequests",
      },
      {
        title: "Pagos",
        routerName: "UserPayments",
      },
      // {
      //   title: "Mis favoritos",
      //   routerName: "UserFavorites",
      // },
    ],
    desktopNavbar: [
      {
        title: "Inicio",
        routerName: "UserMainPage",
      },
      {
        title: "Proveedores",
        routerName: "UserProviders",
      },
      {
        title: "Servicios",
        routerName: "UserServices",
        // menuHandler: false,
        // menu: [
        //   "Salones de eventos",
        //   "Mobiliario",
        //   "Diseño de eventos",
        //   "Música",
        //   "Fotógrafos",
        //   "Pastelerías",
        //   "Ambientación",
        //   "Banquetes",
        //   "Carpas",
        //   "Catering",
        //   "Entretenimiento",
        //   "Iluminación",
        //   "Invitaciones",
        //   "Mesas de dulces",
        //   "Pistas de baile",
        //   "Recuerdos",
        //   "Video",
        // ],
      },
      {
        title: "Paquetes",
        routerName: "UserPackages",
      },
    ],
    menu: [
      {
        title: "Mi perfil",
        routeName: "UserProfile",
        bold: true,
      },
      {
        title: "Ayuda",
        routeName: "UserHelp",
      },
      {
        title: "Cerrar sesión",
        routeName: "SignOut",
      },
    ],
  }),
  computed: mapState({
    provider(state) {
      return state.provider;
    },
    user(state) {
      if (state.user) {
        return state.user;
      } else {
        return { photo: null };
      }
    },
  }),
  methods: {
    handleMenuRedirect(route) {
      if (route == "SignOut") {
        this.signOut();
      } else {
        this.$router
          .push({
            name: route,
          })
          .catch(() => {});
      }
    },
    async signOut() {
      try {
        await Auth.signOut();
        await this.$store.dispatch("signedIn", false);
        await this.$store.dispatch("saveCognitoUser", null);
        this.$router.push({ name: "MainPage" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  span {
    font-size: 18px !important;
  }
  &:hover {
    .navbar-title {
      transform: scale(1.01);
    }
  }
  &.navbar-active .navbar-title {
    transform: scale(1.01);
  }
}

.purple-gradient {
  background: white
    linear-gradient(
      to top,
      rgba(98, 37, 130, 0.6) 1%,
      rgba(98, 37, 130, 1) 100%
    );
  background-size: cover !important;
}

.purple-gradient-background {
  background: linear-gradient(
    to top,
    rgba(98, 37, 130, 0.2) 0%,
    rgba(196, 196, 196, 0) 90%
  );
}

.btn-cristal {
  background: rgba(255, 255, 255, 0.3) !important;
}

.slide-y-transition-leave-to {
  transform: none;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.two-column-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
