var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.page === 'main-page' &&
    !(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) &&
    'purple-gradient'},[_c('v-app-bar',{staticClass:"px-2 px-sm-5",attrs:{"height":_vm.$vuetify.breakpoint.xs ? 70 : 88,"flat":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) || _vm.page === 'user',"color":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) &&
      _vm.page === 'main-page'
        ? 'primary'
        : 'transparent'}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",attrs:{"dark":"","ripple":false},on:{"click":function($event){_vm.drawer = true}}}),_c('v-btn',{staticClass:"hidden-sm-and-down pl-0",attrs:{"depressed":"","plain":"","dark":"","ripple":false,"to":{ name: 'UserMainPage' }}},[_c('v-img',{attrs:{"src":require("@/assets/icons/LogoZaturnaHorizontal.svg")}})],1),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down align-center"},_vm._l((_vm.desktopNavbar),function(section,i){return _c('span',{key:i},[(!section.menu)?_c('v-btn',{staticClass:"text-h4 btn-background-transparent v-btn--active",attrs:{"depressed":"","text":"","dark":"","ripple":false,"active-class":"navbar-active font-weight-bold","router":"","exact":section.title === 'Inicio',"to":{ name: section.routerName }}},[_c('span',{staticClass:"navbar-title"},[_vm._v(_vm._s(section.title))])]):_c('v-menu',{attrs:{"offset-y":"","rounded":"md","transition":"slide-y-transition","open-on-hover":"","close-on-content-click":false,"open-delay":350,"close-delay":100,"min-width":"658"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-caption btn-background-transparent v-btn--active",class:section.menuHandler && 'navbar-active font-weight-bold',attrs:{"depressed":"","text":"","dark":"","ripple":false,"active-class":"navbar-active font-weight-bold","router":"","to":{ name: section.routerName }}},'v-btn',attrs,false),on),[_c('span',{staticClass:"navbar-title"},[_vm._v(_vm._s(section.title))])])]}}],null,true),model:{value:(section.menuHandler),callback:function ($$v) {_vm.$set(section, "menuHandler", $$v)},expression:"section.menuHandler"}},[_c('v-card',{staticClass:"py-6 px-9"},[_c('v-card-title',{staticClass:"pa-0 font-weight-bold text-h5"},[_vm._v(" Servicios Zaturnas ")]),_c('div',{staticClass:"my-2 ml-1 font-weight-bold text-subtitle-1"},[_vm._v(" Los más populares ")]),_c('v-card-text',{staticClass:"pa-0 text-subtitle-1"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"px-4 py-2 purple-gradient-background rounded-md",staticStyle:{"width":"234px"},attrs:{"cols":"5"}},_vm._l((section.menu.slice(0, 6)),function(item,idx){return _c('div',{key:idx,class:idx == 0 ? 'mt-0' : 'mt-2'},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none black--text",class:hover
                            ? 'text-decoration-underline font-weight-medium'
                            : '',attrs:{"to":{
                          name: section.routerName,
                          query: {
                            tipo: item,
                          },
                        }}},[_c('span',{on:{"click":function($event){section.menuHandler = false}}},[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,true)})],1)}),0),_c('v-col',{staticClass:"px-5 py-2 two-column-list",attrs:{"cols":"7"}},[_vm._l((section.menu.slice(6, 17)),function(item,idx){return _c('div',{key:idx,staticClass:"ml-5",class:idx == 0 ? 'mt-0' : 'mt-2'},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none black--text",class:hover &&
                          'text-decoration-underline font-weight-medium',attrs:{"to":{
                          name: section.routerName,
                          query: {
                            tipo: item,
                          },
                        }}},[_c('span',{on:{"click":function($event){section.menuHandler = false}}},[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,true)})],1)}),_c('div',{staticClass:"ml-5 mt-2"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none font-weight-bold black--text",class:hover && 'text-decoration-underline',attrs:{"to":{
                          name: section.routerName,
                          params: { autofocusServices: true },
                        },"replace":""}},[_c('span',{on:{"click":function($event){section.menuHandler = false}}},[_vm._v(" Ver todos ")])])]}}],null,true)})],1)],2)],1)],1)],1)],1)],1)}),0),_c('v-spacer'),_c('v-toolbar-items',{staticStyle:{"overflow-x":"hidden"}},[_c('v-btn',{staticClass:"text-caption btn-background-transparent v-btn--active",attrs:{"depressed":"","text":"","dark":"","ripple":false,"to":{ name: _vm.provider ? 'ProviderHomepage' : 'RegisterProvider' }}},[_c('span',{staticClass:"navbar-title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.provider ? "Modo proveedor" : "Registro proveedor")+" ")])]),_c('v-menu',{attrs:{"offset-y":"","offset-x":"","open-on-hover":!_vm.$vuetify.breakpoint.xs,"rounded":"md","transition":"slide-y-transition","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-flex align-center pl-1 my-2 my-sm-4"},'a',attrs,false),on),[(_vm.user['photo'])?_c('v-img',{staticClass:"rounded-circle",attrs:{"aspect-ratio":1,"src":_vm.user['photo'],"width":"34"}}):_c('v-icon',{attrs:{"size":"30","color":"white"}},[_vm._v("fa-user-circle")]),(_vm.user['preferedName'])?_c('span',{staticClass:"hidden-md-and-down white--text ml-3"},[_vm._v(" "+_vm._s(_vm.user["preferedName"])+" ")]):_vm._e()],1)]}}])},[_c('v-card',{attrs:{"tile":"","min-width":"180px"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,class:i == 0 ? 'pt-1' : i == _vm.menu.length - 1 && 'pb-1',on:{"click":function($event){return _vm.handleMenuRedirect(item.routeName)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-1",class:{
                    'font-weight-black': item.bold,
                  },domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","ripple":false},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-btn',{staticClass:"d-flex justify-center primary rounded-0 v-btn--active",attrs:{"height":_vm.$vuetify.breakpoint.xs ? 70 : 88,"depressed":"","plain":"","ripple":false,"to":{ name: 'UserMainPage' }},on:{"click":function($event){_vm.drawer = false}}},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? 45 : 53,"contain":"","src":require("@/assets/icons/LogoZaturnaHorizontal.svg")}})],1),_c('v-list',{staticClass:"py-0"},[_c('v-list-item-group',{attrs:{"active-class":"secondary--text"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.mobileNavbar),function(item,i){return _c('v-list-item',{key:i,attrs:{"router":"","to":{ name: item.routerName },"exact":item.title === 'Inicio'}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }